import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import InnovationHomeSlider from "../InnovationHomeSlider"

const InnovationHomeSection = ({ intl, data }) => {
  return (
    <InnovationHomeSectionWrapper>
      {process.env.GATSBY_ACTIVE_ENV != "gbp" && (
        <TopFlagSection></TopFlagSection>
      )}
      {process.env.GATSBY_ACTIVE_ENV != "gbp" && (
        <DesktopImageWrapper flag_image={data.flag_image} />
      )}
      {process.env.GATSBY_ACTIVE_ENV != "gbp" && <MobileFlag></MobileFlag>}
      <InnovationContentWrapper store={process.env.GATSBY_ACTIVE_ENV}>
        {data.list && (
          <DesktopInnovationContentBox>
            {data.list.map((item, index) => {
              return (
                <Row
                  key={index.toString()}
                  direction={index === 0 ? "row-reverse" : "row"}
                  paddingSide={index === 0 ? "bottom" : "top"}
                  maxHeight={index === 1 ? "580px" : "380px"}
                >
                  <LeftBox borderSide={index === 0 ? "left" : "right"}>
                    <DesktopImage
                      className="lazyload"
                      data-src={item.desktop_image}
                      alt={intl.formatMessage({
                        id:
                          index === 0
                            ? "dispositif_medical_percko_technologie"
                            : "equipe_percko_reunion",
                      })}
                    />
                    {index === 0 && data.medical_logo && (
                      <MedicaleLogo
                        className="lazyload"
                        data-src={data.medical_logo}
                      />
                    )}
                    <MobileImage
                      className="lazyload"
                      data-src={item.mobile_image}
                      alt={intl.formatMessage({
                        id:
                          index === 0
                            ? "dispositif_medical_percko_technologie"
                            : "equipe_percko_reunion",
                      })}
                    />
                  </LeftBox>
                  <RightBox order={index}>
                    <ContentBox order={index}>
                      {item.title && (
                        <SectionTitle
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                      )}
                      {item.text && (
                        <TextBox>
                          <Text
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          />
                        </TextBox>
                      )}
                      {item.button_url && item.button_text && (
                        <ButtonWrapper>
                          <ProductButton to={item.button_url}>
                            {item.button_text}
                          </ProductButton>
                        </ButtonWrapper>
                      )}
                    </ContentBox>
                  </RightBox>
                </Row>
              )
            })}
          </DesktopInnovationContentBox>
        )}
        {data.list && (
          <MobileInnovationContentBox>
            <InnovationHomeSlider data={data} />
          </MobileInnovationContentBox>
        )}
      </InnovationContentWrapper>
    </InnovationHomeSectionWrapper>
  )
}

const InnovationHomeSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  background: #e5e5e5;
  position: relative;
`

const DesktopImageWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  height: 111px;
  background: ${({ flag_image }) =>
    flag_image
      ? `url(${flag_image})`
      : "https://static.percko.com/uploads/94b0977e-0173-4e22-a0e5-ab3db9871400.png"};
  background-repeat: no-repeat;
  background-position: 20px 0px;
  max-width: 1080px;
  padding-left: 20px;
  position: absolute;
  left: 0px;
  right: 0px;

  @media (max-width: 850px) {
    display: none;
  }
`

const TopFlagSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  position: absolute;
  right: 0px;
  height: 40px;
  background: url(https://static.percko.com/uploads/f3f816b7-254f-4464-a72b-ec30ab9f033a.png);
  background-repeat: repeat;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobileFlag = styled.div`
  display: none;
  flex-direction: column;
  height: 40px;
  background: url(https://static.percko.com/uploads/f3f816b7-254f-4464-a72b-ec30ab9f033a.png);
  background-repeat: repeat;

  @media (max-width: 850px) {
    display: flex;
  }
`

const InnovationContentWrapper = styled.div`
  width: 100%;
  margin-top: ${({ store }) => (store != "gbp" ? "40px" : "0px")};

  @media (max-width: 850px) {
    margin-top: 0px;
  }
`

const DesktopInnovationContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileInnovationContentBox = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;

  @media (max-width: 550px) {
    display: flex;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  max-height: ${({ maxHeight }) => (maxHeight ? "580px" : "380px")};
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  padding-top: ${({ paddingSide }) => (paddingSide === "top" ? "25px" : "0px")};
  padding-bottom: ${({ paddingSide }) =>
    paddingSide === "bottom" ? "25px" : "0px"};
  position: relative;

  @media (max-width: 550px) {
    flex-direction: column-reverse;
    padding: 0px;
    max-height: 100%;
    height: 100%;
    min-height: 580px;
    justify-content: space-between;
  }

  @media (max-width: 480px) {
    min-height: 520px;
  }
`

const LeftBox = styled.div`
  display: flex;
  width: 100%;
  border-top-right-radius: ${({ borderSide }) =>
    borderSide === "right" ? "100px" : "0px"};
  border-bottom-left-radius: ${({ borderSide }) =>
    borderSide === "left" ? "100px" : "0px"};
  overflow: hidden;
  position: relative;

  @media (max-width: 550px) {
    border-radius: 0px;
    border-top-right-radius: ${({ borderSide }) =>
      borderSide === "right" ? "100px" : "0px"};
    border-bottom-left-radius: 0px;
    overflow: initial;
  }
`

const RightBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ order }) => (order === 0 ? "flex-end" : "flex-start")};
  align-items: center;
`

const DesktopImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0px;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0px;
  display: none;

  @media (max-width: 550px) {
    display: flex;
  }
`

const MedicaleLogo = styled.img`
  width: 100%;
  object-fit: contain;
  max-width: 100px;
  margin: 0px;
  position: absolute;
  bottom: 30px;
  left: 50px;
`

const TextBox = styled.div`
  color: #262626;
  width: 100%;
  margin: 15px 0px;

  @media (max-width: 550px) {
    margin: 15px 0px 0px;
  }
`

const Text = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: #262626;

  div {
    margin: 15px 0px;
    font-family: "Gotham";
    font-weight: bold;
  }

  div span {
    font-size: 40px;
    line-height: 1;
    font-family: "Gotham";
    font-weight: bold;
    color: #262626;
    background: -webkit-linear-gradient(#a5fab7, #00c4c1, #50b5d5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  small {
    font-size: 13px;
    line-height: 1.2;
    font-family: "Gotham Book";
    color: #262626;
  }
`

const ContentBox = styled.div`
  padding: ${({ order }) =>
    order === 0 ? "20px 10% 20px 20px" : "20px 20px 20px 10%"};
  width: 100%;
  max-width: 540px;

  @media (max-width: 1200px) {
    padding-top: ${({ order }) => (order === 0 ? "50px" : "20px")};
  }

  @media (max-width: 850px) {
    padding-top: 20px;
  }

  @media (max-width: 550px) {
    padding: 20px;
  }
`

const SectionTitle = styled.div`
  font-size: 32px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;

  @media (max-width: 850px) {
    font-size: 24px;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 1;

  @media (max-width: 550px) {
    position: absolute;
    bottom: 30px;
  }
`

const ProductButton = styled(Link)`
  padding: 12px 20px;
  color: #262626;
  font-family: "Museo";
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  position: relative;
  border: 1px solid ${props => props.theme.colors.border.blue};
  border-radius: 25px 0;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    border: 0px;
  }

  @media (max-width: 550px) {
    width: calc(100% - 40px);
    text-align: center;
  }
`

export default injectIntl(InnovationHomeSection)
